import { Anket } from './enket-type';
import { AnkentService } from './../services/ankent.service';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { anketler } from './enket-type';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-anket',
  templateUrl: './anket.component.html',
  styleUrls: ['./anket.component.scss']
})
export class AnketComponent implements OnInit, AfterViewInit {

  currentRate: number = 6;
  anketler: Anket[];
  anketForm: FormGroup;
  customer: any;
  customerId: any;
  status: boolean = false;

  constructor(
    private _anketService: AnkentService,
    private _formBuilder: FormBuilder,
    private _router: Router,
    private _activatedRouter: ActivatedRoute,
    config: NgbRatingConfig

  ) {

    config.max = 5;
    config.readonly = true;

    this.anketler = anketler;
    this.anketForm = this._formBuilder.group({
      anket: this._formBuilder.array([]),
    });

    this.anketler.forEach(anket => {

      const anketForm = this._formBuilder.group(
        {
          title: [anket.title],
          type: [anket.type],
          info: [anket.info],
          rate: ['', anket.type !== 'text' ? Validators.required : null],
        }
      );


      (this.anketForm.get('anket') as FormArray).push(anketForm);
    });


    console.log(this.anketForm)


  }

  ngOnInit(): void {
    this.customerId = this._activatedRouter.snapshot.paramMap.get('id');
    console.log(this.customerId);
   }
   ngAfterViewInit(): void{

     }


  onSubmit(): void {
    this._anketService.setCustomer(this.customerId, this.anketForm.getRawValue()).subscribe(result => {
      this.status = true;
    })

  }

  cl(info: any): void {
    console.log(info);
  }

}
