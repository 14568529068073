import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, switchMap, take, tap, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AnkentService {

  private _customer: BehaviorSubject<null>;

  constructor(
    private _httpClient: HttpClient
  ) {

    this._customer = new BehaviorSubject(null);

  }


  get customer$(): Observable<any> {
    return this._customer;
  }

  setCustomer(customerId, forms): Observable<any> {
    return this._httpClient.post('http://4mdanismanlik.net/api/index.php',{
      action: 'customer',
      formtype: 'customer',
      customerid: customerId,
      form : forms
    } ).pipe(
      tap((customer) => {
        this._customer.next(customer);
      })
    );
  }

}
