<section class="anket-main">
  <section class="container">

    <div *ngIf="!status; else: messageSuccess">

      <div class="anket-header mb-5">
        <h4 class="">4M Müşteri Memnuniyeti Anketi</h4>
        <p class="lead">4M 'in sürekli gelişme yolculuğuna geri bildiriminiz ile sağlayacağınız katkı için şimdiden teşekkür ederiz.</p>
    </div>

    <form [formGroup]="anketForm" (submit)="onSubmit()">
      <section class="anket-wrap">
        <div class="anket" *ngFor="let anket of anketForm.get('anket')['controls']; let i = index;">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-7">
                  <div class="anket-info">
                    <h5 class="card-title">{{ anket.get('title').value }}</h5>
                    <p class="card-text" *ngIf="anket.get('info').value">{{anket.get('info').value}}</p>
                  </div>

                </div>
                <div class="col-md-5">

                  <div class="rate-wrap">
                    <ng-container *ngIf="anket.get('type').value == 'big'">
                      <div class="form-check">
                        <input class="form-check-input" type="radio" [name]="'anket_'+i" [formControl]="anket.get('rate')" [id]="'anket_1_'+i"
                          value="1">
                        <label class="form-check-label" [for]="'anket_1_'+i">1 - Hiç memnun değilim</label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" [name]="'anket_'+i" [formControl]="anket.get('rate')"  [id]="'anket_2_'+i"
                          value="2">
                        <label class="form-check-label" [for]="'anket_2_'+i">2 - Memnun değilim</label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" [name]="'anket_'+i" [formControl]="anket.get('rate')"  [id]="'anket_3_'+i"
                          value="3">
                        <label class="form-check-label" [for]="'anket_3_'+i">3 - Memnunum</label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" [name]="'anket_'+i" [formControl]="anket.get('rate')"  [id]="'anket_4_'+i"
                          value="4">
                        <label class="form-check-label" [for]="'anket_4_'+i">4 - Gayet memnunum</label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" [name]="'anket_'+i" [formControl]="anket.get('rate')"  [id]="'anket_5_'+i"
                          value="5">
                        <label class="form-check-label" [for]="'anket_5_'+i">5 - Çok memnunum</label>
                      </div>




                    </ng-container>
                    <ng-container *ngIf="anket.get('type').value == 'small'">
                      <div class="form-check">
                        <input class="form-check-input" type="radio" [name]="'anket_1_'+i" [formControl]="anket.get('rate')" [id]="'anket_6_'+i"
                          value="evet">
                        <label class="form-check-label" [for]="'anket_6_'+i">Evet</label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" [name]="'anket_1_'+i" [formControl]="anket.get('rate')" [id]="'anket_7_'+i"
                          value="hayır">
                        <label class="form-check-label" [for]="'anket_7_'+i">Hayır</label>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="anket.get('type').value == 'text'">
                      <textarea class="form-control" rows="3" [formControl]="anket.get('rate')"></textarea>
                    </ng-container>
                  </div>
                </div>
              </div>

              <div *ngIf="anket.get('rate').errors?.required"
              class="alert alert-danger mt-2">

                <div>
                  Lütfen seçim yapın
                </div>
              </div>

            </div>
          </div>
        </div>
        <div>
          <button [disabled]="!anketForm.valid" class="btn btn-primary" type="submit">Anketi Tamamla</button>
        </div>
      </section>
    </form>
    </div>

    <ng-template #messageSuccess>
      <div class="success">
        <div class="alert alert-primary" role="alert">
          Anketiniz sistemimize kayıt edilmiştir. İlginiz için teşekkür ederiz.
        </div>

      </div>
    </ng-template>

  </section>
</section>

