export interface Anket
{
  title: string;
  info?: string;
  rate: number;
  type: AnketType;
}

export type AnketType = 'big' | 'small' | 'text';


export const anketler: Anket[] = [
  {
    title: '4M\' in hizmeti beklentilerinizi karşıladı mı? Memnun kaldınız mı?',
    info: '1\' den 5\' e kadar kaç puan verirsiniz?',
    rate: 0,
    type: 'big'
  },
  {
    title: '4M Uzmanımızın yaklaşımı, yönlendirme ve bilgilendirmeleri için',
    info: '1\' den 5\' e kadar kaç puan verirsiniz?',
    rate: 0,
    type: 'big'
  },
  {
    title: 'Tekrar bizimle çalışmak ister misimiz?',
    info: '4M\' i tanıdıklarınıza tavsiye eder misiniz?',
    rate: 0,
    type: 'small'
  },
  {
    title: 'Diğer hizmetlerimizden haberdar mısınız?',
    rate: 0,
    type: 'small'
  },
  {
    title: 'Size daha iyi hizmet verebilmemiz için görüşlerinizi paylaşmak ister misiniz?',
    rate: 0,
    type: 'text'
  }
];
